import React from "react";
import "./Section1AR.css";

export default function Section1({
  heading = {},
  subHeading = "",
  buttons = {},
  image = {},
  mobileImage = {}
}) {
  return (
    <div className="d-flex section1">
      <div className="heading-wrap">
        {heading}
        <div className="fs-4 pt-4 subheading mr-2">{subHeading}</div>
        <div className="mobile-image">{mobileImage}</div>
        <div className="d-flex flex-column flex-md-row gap-3 gap-md-5 buttons-group">{buttons}</div>
      </div>
      <div className="image-container ml-2">{image}</div>
    </div>
  );
}