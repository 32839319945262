import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { cookieService, userService } from "../_services";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { checkData } from "../utils/checkData";
import { api } from "../middleware/api";
import ValidationErrorToast from "./validationErrorToast";

const Logout = (props) => {
  const dispatch = useDispatch();
  const { resetState, closeOnlineOfflineSocket } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const handleClick = (e) => {
    e.preventDefault();
    closeOnlineOfflineSocket(true);
    userService
      .logout()
      .then((res) => {
        api
          .deleteApi("device-token/", {
            deviceType: "WEB",
            token: `${cookieService.getCookie("fcm")}`,
          })
          .then((res) => {
            // localStorage.removeItem("fcm")
            cookieService.removeAllCookies();
            props.history.push(STRING_CONSTANTS.navigationString.login);
            resetState();
            props.handleClose();
            // window.location.reload()
          })
          .catch((e) => {
            cookieService.removeAllCookies();
            props.history.push(STRING_CONSTANTS.navigationString.login);
            resetState();
            props.handleClose();
            // ValidationErrorToast(e[0], e[1])
          });
      })
      .catch((err) => {
        checkData(props, "/", err);
      });
  };

  return (
    <Modal
      className="bg-transparent logout-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img alt="logout" src={UtilityFunctions.getImageURL() + "logout.svg"} />
        <h3 className="py-2">
          {STRING_CONSTANTS.formLabelString.label_logout_popup}
        </h3>
        <p className="py-3">{STRING_CONSTANTS.modal_text.logout_text}</p>
        <div>
          <Button
            className="ms-4 border-0 bg-transparent cancel-button"
            variant="primary"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            className="ms-4 border-0"
            variant="primary"
            onClick={handleClick}
          >
            Logout
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default withRouter(Logout);
